import React from 'react';
import { RealEstateContentModule } from '@finn-no/realestate-content-module';
import type { AppProps } from '@finn-no/realestate-content-module';
import './styles.css';

export default function App(props: AppProps) {
    return (
        <React.StrictMode>
            <RealEstateContentModule {...props} />
        </React.StrictMode>
    );
}
