import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import type { AppProps } from '@finn-no/realestate-content-module';
import App from './App.js';

const rootNodes = document.querySelectorAll('[data-content-module-root]');

rootNodes.forEach((rootNode) => {
    const renderNode = rootNode.querySelector('[data-content-module]');
    const dataNode = rootNode.querySelector(
        '[data-content-module-data]',
    ) as HTMLElement;
    const initialState =
        dataNode.textContent && (JSON.parse(dataNode.textContent) as AppProps);
    if (renderNode && initialState) {
        hydrateRoot(renderNode, <App {...initialState} />);
    } else {
        // eslint-disable-next-line no-lonely-if
        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.log('missing app and/or data element');
        }
    }
});
